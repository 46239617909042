<template>
  <div v-if="show"></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CountersComponent",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    user() {
      let _self = this;
      if (this.user && "ym" in window) {
        ym(86134583, "params", {
          user: _self.user.name,
        });
      }
    },
  },
  created() {
    this.initMetrika();
  },
  methods: {
    initMetrika() {
      let _self = this;
      (function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(
        window,
        document,
        "script",
        "https://mc.yandex.ru/metrika/tag.js",
        "ym"
      );

      let ymParams = {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        params: {},
      };
      if (_self.user) {
        ymParams.params.user = _self.user.name;
      }

      ym(86134583, "init", ymParams);
    },
  },
};
</script>