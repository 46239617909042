<template>
  <v-app>
    <MenuComponent :isAuth="isAuth" />
    <v-main>
      <v-container v-if="alert">
        <v-alert dense outlined :type="getAlertType()">
          <template v-if="'danger' in alert">{{ alert.danger }}</template>
          <template v-if="'warning' in alert">{{ alert.warning }}</template>
          <template v-if="'success' in alert">{{ alert.success }}</template>
        </v-alert>
      </v-container>
      <template v-if="isAuth">
        <router-view />
      </template>
      <template v-else>
        <AuthComponent />
      </template>
    </v-main>
    <UpdateComponent />
    <CountersComponent />
  </v-app>
</template>

<script>
import MenuComponent from "./components/MenuComponent";
import UpdateComponent from "./components/UpdateComponent";
import AuthComponent from "./components/AuthComponent";
import CountersComponent from "./components/CountersComponent";
import { mapState } from "vuex";
import { registerEvent } from "./functions";

export default {
  name: "App",
  data() {
    return {
      socket: null,
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      alert: (state) => state.alert,
      user: (state) => state.user,
    }),
    isAuth() {
      return this.token ? true : false;
    },
  },
  watch: {
    token() {
      localStorage.setItem("token", this.token);
    },
    user() {
      localStorage.setItem("user", JSON.stringify(this.user));
      this.userEvent();
    },
    alert() {
      if (this.alert) {
        let _self = this;
        setTimeout(() => {
          _self.removeAlert();
        }, 5000);
      }
    },
  },
  created() {
    this.userEvent();
    this.initSSE();
  },
  methods: {
    initSSE() {
      let _self = this;
      let sse = new EventSource(`${this.$store.state.host}/sse`);
      sse.addEventListener(
        "message",
        (event) => {
          _self.updateSSEData(JSON.parse(event.data));
        },
        false
      );
    },
    updateSSEData(states) {
      this.$store.dispatch("set", { states: states });
    },
    getAlertType() {
      let type;
      if ("danger" in this.alert) {
        type = "error";
      } else if ("warning" in this.alert) {
        type = "warning";
      } else if ("success" in this.alert) {
        type = "success";
      }
      return type;
    },
    removeAlert() {
      this.$store.dispatch("set", { alert: null });
    },
    userEvent() {
      if (this.user) {
        let _self = this;
        let event = new CustomEvent("user_init", {
          detail: {
            user_id: _self.user.id,
          },
        });
        document.dispatchEvent(event);
      }
    },
  },
  components: {
    MenuComponent,
    AuthComponent,
    UpdateComponent,
    CountersComponent,
  },
};
</script>

<style lang="less">
.v-main {
  background-color: #eee;
}
</style>