let api = function(root, data, path = location.pathname, resolve = false, loader = true) {
    data.set('token', root.$store.state.token);
    root.$store.dispatch('set', { loader: loader });
    fetch(root.$store.state.host + path, {
        method: 'POST',
        body: data
    }).then(response => {
        return response.json();
    }).then(response => {
        for (let i in response) {
            if (i in root) {
                root[i] = response[i];
            }
            if (i == 'alert') {
                root.$store.dispatch('set', { alert: response[i] });
            }
        }
        if (resolve) {
            resolve(response);
        }
        root.$store.dispatch('set', { loader: false });
    });
}

export default api;