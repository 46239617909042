<template>
  <v-container>
    <v-form @submit.prevent="auth()">
      <v-text-field
        v-model="login"
        label="Имя пользователя"
        required
      ></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
        :type="showPassword ? 'text' : 'password'"
        label="Пароль"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
      <v-btn
        :disabled="!login || !password"
        color="success"
        class="mr-4"
        type="submit"
      >
        Подтвердить
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import api from "../api";

export default {
  name: "auth-component",
  data() {
    return {
      login: "",
      password: "",
      showPassword: false,
      token: "",
      user: null
    };
  },
  watch: {
    user(){
      this.$store.dispatch("set", { user: this.user });
    },
    token() {
      this.$store.dispatch("set", { token: this.token });
    }
  },
  methods: {
    auth() {
      let data = new FormData();
      data.set("type", "auth");
      data.set("Login[username]", this.login);
      data.set("Login[password]", this.password);
      api(this, data, "/login");
    },
  },
};
</script>

<style></style>
