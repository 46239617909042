<template>
  <div>
    <v-app-bar color="deep-purple accent-4" dark>
      <v-app-bar-nav-icon
        v-if="isAuth"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <template v-if="isAuth">
        <v-icon>mdi-phone</v-icon>
        <span v-html="'&nbsp;'"></span>
        <span>{{ countCalls }}</span>
        <span v-html="'&nbsp;'"></span>
        <span v-html="'&nbsp;'"></span>
        <v-icon>mdi-clock-time-three-outline</v-icon>
        <span v-html="'&nbsp;'"></span>
        <span>{{ duration }}</span>
        <span v-html="'&nbsp;'"></span>
      </template>
    </v-app-bar>

    <div v-if="loader" class="progress-container">
      <v-progress-circular
        :size="100"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-navigation-drawer v-if="isAuth" v-model="drawer" absolute left temporary>
      <v-container>
        <v-select
          :items="accounts"
          item-value="phone"
          item-text="name"
          label="Аккаунт"
          :value="account_id"
          @change="setAccount($event)"
        ></v-select>
      </v-container>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <template v-for="(item, key) in items">
            <v-list-item :key="key" :to="item.path">
              {{ item.name }}
            </v-list-item>
          </template>
          <hr />
          <v-list-item>
            <a href="#" @click.prevent="logout()">Выйти</a>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "../api";

export default {
  name: "menu-component",
  props: {
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        name: "Звонки",
        path: "/",
      },
    ],
    accounts: [],
  }),
  computed: {
    ...mapState({
      loader: (state) => state.loader,
      account_id: (state) => state.account_id,
      duration: (state) => state.duration,
      countCalls: (state) => state.countCalls,
    }),
  },
  watch: {
    group() {
      this.drawer = false;
    },
    isAuth() {
      this.getAccounts();
    },
  },
  created() {
    this.getAccounts();
    this.setGroupMenu();
  },
  methods: {
    api(data, resolve = false) {
      api(this, data, "/", resolve);
    },
    getAccounts() {
      if (!this.isAuth) {
        return;
      }
      let data = new FormData();
      data.set("type", "getAccounts");
      this.api(data);
    },
    setGroupMenu() {
      let group = null;
      for (let i in this.items) {
        let item = this.items[i];
        if (this.$route.path == item.path) {
          group = Number(i);
        }
      }
      this.group = group;
    },
    logout() {
      if (!confirm("Вы уверены, что хотите выйти?")) {
        return;
      }
      localStorage.clear();
      location.reload();
    },
    toRoute(path) {
      this.$router.push(path);
    },
    setAccount(event) {
      localStorage.setItem("account_id", event);
      this.$store.dispatch("set", { account_id: event });
    },
  },
};
</script>

<style lang="less">
aside {
  a {
    text-decoration: none;
    color: black !important;
  }
}
.progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .v-progress-linear {
    position: absolute;
    top: 0;
  }
}
</style>