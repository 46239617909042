import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const origin = 'https://calls.sv34.ru';

export default new Vuex.Store({
    state: {
        version: '1.01',
        origin: origin,
        host: `${origin}/appeal-api`,
        loader: false,
        token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        alert: null,
        visibilityState: true,
        account_id: localStorage.getItem('account_id') ? localStorage.getItem('account_id') : null,
        duration: 0,
        countCalls: 0,
        states: []
    },
    mutations: {
        set(state, obj) {
            let key = Object.keys(obj)[0];
            state[key] = obj[key];
        }
    },
    actions: {
        clear({ commit }, key) {
            commit("set", {
                [key]: null
            });
        },
        set({ commit }, obj) {
            commit("set", obj);
        },
    }
});