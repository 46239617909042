<template>
  <v-snackbar
    bottom
    right
    :value="updateExists"
    color="deep-purple"
  >
    <v-row>
      <v-col cols="7" class="d-flex align-center">
        доступна новая версия
      </v-col>
      <v-col cols="5">
        <v-btn text @click="refreshApp" color="warning"> Обновить </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: "update-component",
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false,
  }),
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>
